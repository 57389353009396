import React, { Component } from 'react';
import moment from "moment";
import PropTypes from "prop-types";
import _ from 'lodash';
import { Steps, Calories, Distance } from '../SvgComponents';
import { StyledModal, ActivityContainer, StyledBody, StyledHeader, SaveButton, ButtonContainer, PlaceHolder } from './styles';
import CommonInputBox from '../common/CommonInputBox/CommonInputBox';

class InputGoals extends Component {
  constructor(props) {
    super();
    this.state = {
      steps: _.get(props.inputGoals, 'daily_step', 0),
      calories: _.get(props.inputGoals, 'daily_calorie', 0),
      distance: _.get(props.inputGoals, 'daily_distance', 0)
    }
  }

  changeInputValue = (e) => {
    if (isNaN(Number(e.target.value))) {
      return ;
    }

    else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  };
  updateDailyGoal = () => {
    const { SetDailyGoal, onHide} = this.props;
    let obj = {};
    obj['daily_step_goal'] = this.state.steps;
    obj['daily_calorie_goal'] = this.state.calories;
    obj['daily_distance_goal'] = this.state.distance;
    SetDailyGoal(obj);
    onHide();
  };

  onClosePopup = () => {
    this.setState({
      steps: _.get(this.props.inputGoals, 'daily_step', 0),
      calories: _.get(this.props.inputGoals, 'daily_calorie', 0),
      distance: _.get(this.props.inputGoals, 'daily_distance', 0)
    },() => {
      this.props.onHide()
    })
  }

  render() {
    const { showModal} = this.props;
    const { steps, calories, distance } = this.state;
    return (
      <StyledModal show={showModal} onHide={this.onClosePopup}>
        <StyledHeader closeButton>
          <img src="/public/images/Logo/modal-logo.png" alt="modal logo" />
          <span>DAILY ACTIVITIES</span>
          <span>{moment().format('MM/DD/YYYY')}</span>
        </StyledHeader>
        <StyledBody>
          <ActivityContainer>
            <div>
              <div>
                <Steps color="#159fc9" marginTop="9px" />
                <span>Steps</span>
              </div>
              <div>
                <CommonInputBox type="text" name="steps" onChange={this.changeInputValue} value={steps}/>
                <PlaceHolder>steps</PlaceHolder>
              </div>
            </div>
          </ActivityContainer>
          <ActivityContainer>
            <div>
              <div>
                <Calories color="#159fc9" marginTop="9px" />
                <span>Calories</span>
              </div>
              <div>
                <CommonInputBox type="text" name="calories" onChange={this.changeInputValue} value={calories}/>
                <PlaceHolder>cal</PlaceHolder>
              </div>
            </div>
          </ActivityContainer>
          <ActivityContainer>
            <div>
              <div>
                <Distance color="#159fc9" marginTop="9px" />
                <span>Distance</span>
              </div>
              <div>
                <CommonInputBox type="text" name="distance" onChange={this.changeInputValue} value={distance}/>
                <PlaceHolder>miles</PlaceHolder>
              </div>
            </div>
          </ActivityContainer>
          <ButtonContainer>
            <SaveButton onClick={this.updateDailyGoal}>save</SaveButton>
          </ButtonContainer>
        </StyledBody>
      </StyledModal>
    )
  }
}

InputGoals.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  SetDailyGoal: PropTypes.func,
  inputGoals: PropTypes.object,
};
export default InputGoals;
