import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';

const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: 25px 0 20px 34px;
  > img {
    float: left;
    width: 30px;
  }
  
  > span {
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    padding-left: 10px;
    font-family: ${BoldFont};
    line-height: 32px;

    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  
  > span:last-child {
    font-size: 16px;
    letter-spacing: 0px;
    color: rgb(0,185,255);
    font-weight: normal;
    padding-left: 0;
    font-family: ${RegularFont};
    float: right;
    margin-right: 25px;
  }

  button {
    margin-right: 8px;
    margin-top: -18px !important;

    @media (max-width: 500px) {
      margin-top: -8px !important;
    }
  }

  @media (max-width: 500px) {
    padding: 15px 0 15px 10px;
  }
`;

const StyledBody = styled(Modal.Body)`
  padding: 0;
`;

const ActivityContainer = styled.div`
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #e2e2e4;
  padding: ${(props) => props.input ? '0 1vw' : '0 6vw'};
  display: table;
  
  > div {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    > div {
      width: 50%;
      float: left;
      > svg {
        margin-top: 9px;
        width: 20%;

        @media (max-width: 350px) {
          width: 15%;
        }
      }
      > span {
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-size: 16px;
        padding-left: 10px;
        line-height: 40px;

        @media (max-width: 350px) {
          padding-left: 5px;
        }
      }
    }
  }
  
  ${(props) => props.input && css`
    &:last-child {
      border-bottom: none;
    }
  `}
  
  ${(props) => props.health && css`
    &:last-child {
      border-bottom: 1px solid #e2e2e4;
    }
  `}
`;

const CustomInput = styled.input`
  font-size: 16px;
  padding: 12px 10px;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: right;
  width: 100%;
  padding-right: 25%;
  
  &:hover, &:active, &:focus {
    outline: none;
  }
`;

const PlaceHolder = styled.span`
  position: absolute;
  right: 7vw;
  font-size: 12px !important;
  letter-spacing: 0px;
  color: rgb(153,153,153);
`;

const ButtonContainer = styled.div`
  margin: 25px 0 35px 0;
  text-align: center;
`;

const SaveButton = styled.button`
  background-color: #159fc9;
  border: 1px solid #159fc9;
  color: white;
  white-space: nowrap;
  padding: 10px 50px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: ${BoldFont};
  
  &:focus, &:active, &:hover {
    outline: none;
  }
`;

export { StyledModal, ActivityContainer, StyledHeader, StyledBody, SaveButton, ButtonContainer, CustomInput, PlaceHolder };
