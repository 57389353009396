import styled, {css} from 'styled-components';
import { Modal } from 'react-bootstrap';

const RegularFont = 'Rubik';
const MediunFont = 'Rubik-Medium';
const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
  }
  
  .background {
    width: 100%;
    height: 100%;
    background: #F6F6F8;
  }
`;

const StyledHeader = styled.div`
  padding: 25px 0 20px 0;
  text-align: center;
  font-family: ${RegularFont};
  font-style: normal;
  color: #67676A;

  > div:first-child {
    font-weight: 600;
    font-size: 25px;
    opacity: 0.9;
  }

  > div:nth-child(2) {
    font-weight: normal;
    font-size: 14px;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
  
  @media (max-width: 530px) {
    padding: 25px 5px 20px 5px;
  }
`;

const StepsContainer = styled.div`
  width: 100%;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    align-content: center;
    align-items: center;
    counter-reset: stepCount;
    display: flex;
    justify-content: space-around;
    margin: 10vh auto 20vh;
  }

  li {
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    border-radius: 1em;
    font-size: 23.4px;
    background: #FFFFFF;
    margin: 0 1em;
    display: inline-block;
    color: #282C37;
    position: relative;
  }

  li::before {
    content: '';
    position: absolute;
    top: .9em;
    width: 6em;
    right: 100%;
    height: .2em;
    background: #DEDEE5;;
    z-index: 1;
  }

  li:first-child::before {
    display: none;
  }
  
  .active {
    font-family: ${RegularFont};
    font-style: normal;
    font-weight: 500;
    font-size: 23.4px;
    color: #FFFFFF;
    background: #616271;
  }
`;

const MonthlyGoalsSteps = styled.button`
  width: 45px;
  height: 45px;
  border-radius: 30px;
  background-color: ${({active}) => active ? '#616271' : '#FFFFFF'};
  display: inline-block;
  color: ${({active}) => active ? '#FFFFFF' : '#282C37'};
  border: none;

  font-size: 12px;
  letter-spacing: 0px;
  font-family: ${RegularFont};
  text-align: center;
  cursor: pointer;
  padding: 0;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
  `}

  &:active, &:focus {
    outline: none;
  }
`;

const ConnectingLine = styled.div`
  display: inline-block;
  width: 150px;
  height: 8px;  
  background: #DEDEE5;
  margin-bottom: 3px;

  @media (max-width: 530px) {
    width: 80px;
  }
`;

const Task = styled.div`
  padding: 20px 0 20px 0;
  text-align: center;
  font-family: ${RegularFont};
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  color: #282C37;
`;

const MainContainer = styled.div`
  margin: 20px 60px;
  height: auto;
  background: #F4F4F4;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  @media (max-width: 320px) {
    width: 80%;
    margin: 20px 30px;
  }
`;

const Header = styled.div`
  width: 100%;
  background: #FFFFFF;
  height: 65px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
`;

const SelectedSteps = styled.div`
  display: inline-block;
  > div {
    font-family: Helvetica;
    font-size: 29px;
    color: #1E76AB;
    display: inline-block;
    border-bottom: 3px solid #1E76AB;
    margin-top: 15px;
    line-height: normal;
  }

  > img {
    width: ${({width}) => width ? '27px' : '20px'};
    height: 27px;
    margin: 20px;
  }
`;

const StepsPerDay = styled.div`
  display: inline-block;
  float: right;
  margin: 25px;

  @media (max-width: 530px) {
    margin: 20px 3px;
  }

  > span:first-child {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #23253A;
  }

  > span:nth-child(2) {
    font-family: Helvetica;
    font-size: 9.6px;
    line-height: 11px;
    text-align: center;
    color: #23253A;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
`;

const SliderContainer = styled.div`
  margin: 25px 0 30px 0;
  padding: 10px 30px;

  .zero {
    float: left;
    margin: 10px 5px;
  }

  .steps {
    float: right;
    margin: 10px 5px;
  }
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 16px;
    border-radius: 8px;
    background: ${({value}) => value && `linear-gradient(90deg,#3088BD ${value}%,#FFFFFF ${value}%)`};
    outline: none;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      ${(props) => sliderThumbStyles(props)};
    }
    &::-moz-range-thumb {
      ${(props) => sliderThumbStyles(props)};
    }
  }
`;
const sliderThumbStyles = (props) => (`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props.color};
  cursor: pointer;
  border: 5px solid rgb(30, 118, 171);
  -webkit-transition: .2s;
  transition: opacity .2s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
`);

const Content = styled.div`
  width: 100%;
  height: calc(100% - 50px);
`;

const RecommendedSteps = styled.div`
  font-family: Helvetica;
  font-size: 21px;
  text-align: center;
  color: #484856;
  margin-bottom: 20px;
  padding-bottom: 20px;
  > div {
    font-family: Helvetica;
    font-size: 9.6px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #23253A;
    mix-blend-mode: normal;
    opacity: 0.7;
  }
`;

const Button = styled.div`
  text-align: center;
  margin: 25px 0px 35px;
`;

const ContinueButton = styled.button`
  background: ${({finalStep}) => finalStep ? '#1E76AB' : '#616271'};
  border-radius: 25px;
  font-family: ${RegularFont};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 12px 60px;
  text-align: center;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #FFFFFF;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  border: 1px solid #616271;

  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const GreatingHeader = styled.div`
  padding: 20px 0 20px 0;
  text-align: center;
  font-family: ${RegularFont};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #282C37;
`;

const FinalGoals = styled.div`
  width: ${({width}) => width ? '100%' : '400px'};
  height: ${({width}) => width ? 'auto' : '250px'};
  box-shadow: ${({width}) => width ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'unset'};
  background: #FFFFFF;
  margin: 10px auto;
  border-radius: 6px;
  padding-bottom: ${({width}) => width && '20px'};
  .header {
    text-align: center;
    padding: 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    font-size: ${({font}) => font ? '13px' : '18.2px'};
    letter-spacing: 1.333px;
    text-transform: uppercase;
    //color: #23253A;
     color: #0D4270;
    margin-bottom: ${({font}) => font ? '10px' : '30px'};
  }
`;

const GoalsContainer = styled.div`
  text-align: center;
  padding: ${({bgImage}) => bgImage && '0 10px'};
  display: ${({bgImage}) => bgImage && 'flex'};
  align-items: ${({bgImage}) => bgImage && 'center'};
  justify-content: ${({bgImage}) => bgImage && 'space-between'};
  padding: ${({bgImage}) => bgImage && '0 35px'};
  @media(max-width: 767px){
    justify-content: ${({bgImage}) => bgImage && 'space-around'};
    padding: ${({bgImage}) => bgImage && '0px'};
  }
`;

const SelectedValues = styled.div`
  display: inline-block;
  margin: ${(props) => props.margin };

  > img {
    width: ${(props) => props.width };
    height: ${(props) => props.height };
    margin: ${({stepImage}) => stepImage ? stepImage : '0 0 27px 0' };
  }

  .value {
    font-family: ${MediunFont};
    font-size: 18px;
    text-align: center;
    color: #23253A;
    margin: 10px 0 -5px 0;
  }

  > span {
    font-family: ${RegularFont};
    font-size: 13px;
    color: #23253A;
  }
`;

const CloseIcon = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 85px;
    right: 15px;
`;

export { SelectedValues, GoalsContainer, FinalGoals, GreatingHeader, SelectedSteps, StepsPerDay, ContinueButton, StyledModal, StyledHeader, StepsContainer, MonthlyGoalsSteps, ConnectingLine, Task, MainContainer, Header,
  SliderContainer, Content, RecommendedSteps, Button, CloseIcon };